class BadRequestException {

    constructor(message, exception) {
        this.message = message;
        this.exception = exception;
    }

}

module.exports = BadRequestException;
