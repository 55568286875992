
const BadRequestException = require('../../exceptions/badRequestException');
/**
 * Service interface
 */
class Service {
  static #siteIdLenght = 3;

  static getUrlForCategory(category) {
    const { id: categoryId, name: categoryName } = category;
    const categoryIdWhithoutSiteId = this.#removeSiteId(categoryId);
    return Service.#createUrl(categoryName, categoryIdWhithoutSiteId);
  }

  static getUrlWithoutCategory() {
    return '/';
  }

  static getCategoryIdFromOldBaseUrl(siteId, baseUrl) {
    return Service.#getCategoryIdFromUrl(siteId, baseUrl);
  }

  static getCategoryId(siteId, categoryIdNumber) {
    const parsedCategoryId = parseInt(categoryIdNumber, 10);
    if (!Number.isInteger(parsedCategoryId)) {
      throw new BadRequestException(`The category id is not a valid number. CategoryIdNumber: ${categoryIdNumber}`);
    }
    return siteId + parsedCategoryId;
  }

  static #createUrl(categoryName, categoryIdWhithoutSiteId) {
    const sanitizedCategoryName = Service.#sanitizedText(categoryName);
    return `/${categoryIdWhithoutSiteId}-${sanitizedCategoryName}`;
  }

  static #removeSiteId(categoryId) {
    return categoryId.substring(this.#siteIdLenght);
  }

  static #removeAccents(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  static #replaceQuotes(str) {
    return str.replace(/,/g, '_');
  }

  static #replaceBlanks(str) {
    return str.replace(/ /g, '_');
  }

  static #sanitizedText(text) {
    const replacedQuotesText = Service.#replaceQuotes(text);
    const replacedBlanksText = Service.#replaceBlanks(replacedQuotesText);
    const removedAccentText = Service.#removeAccents(replacedBlanksText);
    return removedAccentText.toLowerCase();
  }

  static #getCategoryIdFromUrl(siteId, baseUrl) {
    if (baseUrl === undefined || baseUrl === '') {
      return undefined;
    }

    const splitUrl = baseUrl.split('-');

    if (splitUrl.length <= 1) {
      throw new BadRequestException(`Url has invalid format. BaseUrl: ${baseUrl}`);
    }

    const firstPart = Service.#removeSlashes(splitUrl[0].trim());
    const categoryIdNumber = parseInt(firstPart, 10);
    if (categoryIdNumber) {
      return siteId + categoryIdNumber;
    }

    throw new BadRequestException(`The category id is not a valid number. BaseUrl: ${baseUrl}`);
  }

  static #removeSlashes(str) {
    return str.replace(/\//g, '');
  }
}

/**
 * Expose Service
 */
module.exports = Service;
